import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { Carousel } from "@components/Carousel"
import { CursorMarker } from "@components/CursorMarker"
import { Arrow } from "@components/UI"
import { AboutJk } from "@components/AboutJk"
import { SalesForm } from "@components/Form"
import { Lottie } from "@components/Lottie"
import { splitText } from "@utils"
import cutLottie from "@lottie/cut_b.json"
import megaphoneLottie from "@lottie/megaphone_b.json"
import repeatLottie from "@lottie/repeat_b.json"

const SalesEnablementPage = ({ data }) => {
  const { heroImageDesktop, heroImageTablet, heroImageMobile } = data
  const { themeColor } = usePageSetup({ themeColor: "bg-purple-light", displayGeneralForm: false })

  return (
    <Layout>
      <Seo title="Sales Enablement" description="" />
      <section className={`${themeColor} overflow-hidden`}>
        <Hero className="pb-clamp-36-54 pt-clamp-16-26">
          <Hero.Title>
            <div className="grid-cols-10 lg:grid">
              <div className="col-span-9">
                <h1
                  data-aos="stagger"
                  dangerouslySetInnerHTML={{
                    __html: splitText({
                      "text-white": "Turn more new leads",
                      "text-gray-darkest": "into loyal customers.",
                    }),
                  }}
                />
              </div>
            </div>
          </Hero.Title>
          <Hero.Message overide={true}>
            <div className="w-full grid-cols-10 lg:grid gap-x-4 mt-11">
              <div className="col-span-7 col-start-2">
                <div data-aos="fade-up">
                  <p>
                    The right sales story makes all the difference—and we’ll help you tell it in all the right places.
                  </p>
                </div>
              </div>
            </div>
          </Hero.Message>
        </Hero>
      </section>

      <Arrow colorTop="bg-gray-darkest" colorBottom="bg-white" fillColor="text-white" />

      <main>
        <section className="overflow-hidden">
          <div className="container relative">
            <div className="absolute inset-x-0 hidden xl:block">
              <div className="lg:aspect-w-1436 lg:aspect-h-570 extend bg-purple-light before:bg-purple-light after:bg-purple-light"></div>
            </div>
          </div>
          <div className="grid w-full grid-rows-12 xl:grid-rows-10">
            <div className="col-start-1 col-end-2 row-start-1 row-end-8 bg-purple-light extend before:bg-purple-light xl:hidden"></div>
            <div className="container flex col-start-1 col-end-2 row-start-1 pr-0 row-end-8 xl:w-extended-10/12 xl:ml-auto xl:mr-0">
              <div className="flex w-full ml-auto sm:w-11/12 xl:w-full">
                <div className="w-full aspect-w-351 aspect-h-590 md:aspect-w-605 md:aspect-h-778 lg:aspect-w-1436 lg:aspect-h-920">
                  <div data-aos="slide-up" data-aos-delay="400">
                    <div className="hidden image-fill xl:block">
                      <GatsbyImage
                        objectFit="cover"
                        loading="eager"
                        image={getImage(heroImageDesktop)}
                        alt="sales enablement"
                      />
                    </div>
                    <div className="hidden image-fill md:block xl:hidden">
                      <GatsbyImage
                        objectPosition="top"
                        objectFit="cover"
                        image={getImage(heroImageTablet)}
                        alt="sales enablement"
                      />
                    </div>
                    <div className="image-fill md:hidden">
                      <GatsbyImage
                        objectPosition="top right"
                        objectFit="cover"
                        image={getImage(heroImageMobile)}
                        alt="sales enablement"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative col-start-1 col-end-2 row-start-5 row-end-12 xl:row-start-4 xl:row-end-10">
              <div className="container flex h-full">
                <div className="relative flex w-5/6 lg:w-8/12 xl:w-7/12">
                  <div
                    data-aos="fade-up"
                    className="relative w-full mt-auto bg-purple extend before:bg-purple md:aspect-w-605 md:aspect-h-790 xl:before:bg-transparent xl:aspect-w-830 xl:aspect-h-732"
                  >
                    <div className="flex items-center grid-cols-7 lg:grid">
                      <div className="col-start-2 col-end-7 py-16 pr-12 text-white md:py-0 lg:pr-0">
                        <p>
                          Sales enablement is more than tools and tactics. It’s telling a clear, compelling,
                          value-focused story about why prospects will want to engage with your company, your solutions,
                          and your people.
                        </p>
                        <p>
                          But too often, the stories sales teams tell are fragmented. Too focused on features and facts.
                          Or misaligned with marketing.
                        </p>
                        <p>
                          Your sales story should be the consistent, compelling thread that runs through all your
                          communications—delivering the right content at the right touchpoints, and making the
                          connections that count with your leads.
                        </p>
                        <p>
                          JK’s sales enablement workshop will help you differentiate your brand, drive buyer preference,
                          and strengthen your sales enablement arsenal.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-clamp-49-73">
          <div className="container">
            <div className="mx-auto lg:grid-cols-10 lg:w-10/12">
              <h2
                className="text-title"
                data-aos="stagger"
                dangerouslySetInnerHTML={{
                  __html: splitText({
                    "text-gray-darkest": "Together, we can:",
                  }),
                }}
              />
            </div>
          </div>
          <div className="container mt-clamp-13-25">
            <div className="grid-cols-6 mx-auto sm:grid lg:grid-cols-10 lg:w-10/12">
              <div className="col-span-5 lg:col-start-2 lg:col-span-7">
                <div data-aos="fade-up">
                  <Lottie animation={cutLottie} />
                </div>
                <div data-aos="fade-right">
                  <h3 className="text-clamp-26-32 mt-clamp-8-15">Craft your story.</h3>
                  <p className="mt-clamp-9-12">
                    Develop a{" "}
                    <span className="font-semi">
                      compelling story and messaging tailored to your customer personas and buyer journeys&mdash;
                    </span>
                    one that showcases your value, makes emotional connections, and sets you apart from the competition.
                  </p>
                </div>
              </div>
            </div>
            <div className="grid-cols-12 mx-auto sm:grid lg:grid-cols-10 lg:w-10/12 mt-clamp-22-25">
              <div className="col-span-9 col-start-4 lg:col-start-5 lg:col-span-7">
                <div data-aos="fade-up">
                  <Lottie animation={megaphoneLottie} />
                </div>
                <div data-aos="fade-left">
                  <h3 className="text-clamp-26-32 mt-clamp-8-15">Empower your salesforce.</h3>
                  <p className="mt-clamp-9-12">
                    Put a practical playbook in their hands—enabling them to{" "}
                    <span className="font-semi">fully understand and persuasively articulate your new sales story</span>{" "}
                    and messaging.
                  </p>
                </div>
              </div>
            </div>

            <div className="grid-cols-6 mx-auto sm:grid lg:grid-cols-10 lg:w-10/12 mt-clamp-22-25">
              <div className="col-span-5 lg:col-start-2 lg:col-span-7">
                <div data-aos="fade-up">
                  <Lottie animation={repeatLottie} />
                </div>
                <div data-aos="fade-right">
                  <h3 className="text-clamp-26-32 mt-clamp-8-15">Deliver with impact.</h3>
                  <p className="mt-clamp-9-12">
                    Bring your sales story to life through{" "}
                    <span className="font-semi">targeted content, collateral, and campaigns designed to engage</span>{" "}
                    prospects and convert them into active buyers—and loyal customers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-white" fillColor="text-white" />

        <section className="overflow-hidden bg-blue-light pt-clamp-49-71 pb-clamp-25-58">
          <div className="container relative flex justify-center">
            <div className="w-full lg:w-10/12">
              <div>
                <h2
                  data-aos="stagger"
                  className="text-title"
                  dangerouslySetInnerHTML={{
                    __html: splitText({
                      "text-white": "Featured project",
                      break: "hidden md:block",
                      "text-gray-darkest": "snapshots.",
                    }),
                  }}
                />
              </div>
              <div className="grid grid-cols-10 mt-clamp-7-11">
                <div className="col-span-9">
                  <Carousel>
                    <CursorMarker>
                      <CursorMarker.Sales className="bg-white after:text-purple" />
                    </CursorMarker>
                    <Carousel.Swiper>
                      <Carousel.Slide>
                        <div data-aos="fade-up">
                          <Carousel.Sales client="signify" />
                        </div>
                      </Carousel.Slide>
                      <Carousel.Slide>
                        <div data-aos="fade-up" data-aos-delay="200">
                          <Carousel.Sales client="heineken" />
                        </div>
                      </Carousel.Slide>
                      <Carousel.Slide>
                        <div data-aos="fade-up" data-aos-delay="400">
                          <Carousel.Sales client="ad" />
                        </div>
                      </Carousel.Slide>
                      <Carousel.Slide>
                        <div data-aos="fade-up" data-aos-delay="600">
                          <Carousel.Sales client="lg" />
                        </div>
                      </Carousel.Slide>
                      <Carousel.Slide>
                        <div data-aos="fade-up" data-aos-delay="800">
                          <Carousel.Sales client="era" />
                        </div>
                      </Carousel.Slide>
                    </Carousel.Swiper>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
        <section className="pt-clamp-44-62 pb-clamp-42-53">
          <AboutJk>
            <AboutJk.Default />
          </AboutJk>
        </section>
        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
        <section className={`${themeColor} pt-clamp-34-51 pb-clamp-27-40`}>
          <div className="container flex lg:justify-center">
            <div className="xl:w-10/12">
              <div>
                <h2
                  data-aos="stagger"
                  className="text-title"
                  dangerouslySetInnerHTML={{
                    __html: splitText({
                      "text-white": "Let’s set your sales team ",
                      break: "hidden sm:block",
                      "text-gray-darkest": "up for success.",
                    }),
                  }}
                />
              </div>
              <div className="grid-cols-10 lg:grid mt-clamp-7-11">
                <div className="col-span-8 xl:col-span-7">
                  <p data-aos="fade-up">Please fill out the form below and we’ll be in touch soon.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-24">
            <div className="mx-auto xl:w-10/12">
              <SalesForm
                id="632"
                subject="JK Design - Sales Enablement"
                recipient="katiek@jkdesign.com, markm@jkdesign.com, hillarym@jkdesign.com"
                fieldColor="bg-teal-light"
              />
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default SalesEnablementPage

export const SalesEnablementQuery = graphql`
  query salesEnablementQuery {
    heroImageDesktop: file(relativePath: { eq: "sales-enablement/hero-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    heroImageTablet: file(relativePath: { eq: "sales-enablement/hero-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    heroImageMobile: file(relativePath: { eq: "sales-enablement/hero-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
